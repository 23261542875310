:root {
    --taskbar-height: 0;
    --white-space: ;
    --calendar-today: ;
    --dark-grey: ;
    --black-white: ;
    --zoidberd-black: ;
    --black-white-colormode: ;
}
.type-token {
    color: white;
    height: var(--taskbar-height);
    padding: 0px 10px 0px 10px;
    border-radius: 25px 0px 0px 25px;
    display: flex;
    align-items: center;
}

.rbc-event-content {
    white-space: var(--white-space);
}

.rbc-month-row {
    overflow: visible;
}

.task-bar-container {
    display: flex;
}

.task-bar-title {
    padding-left: 3px;
}

.rbc-calendar {
    font-family: 'Roboto-Medium';
    margin-bottom: 1.5em;
}

.rbc-event {
    border-radius: 25px;
    padding: 0px;
}

.rbc-row-content {
    display: flex;
    flex-direction: column;
}

.rbc-overlay {
    display: flex;
    flex-direction: column;
}

.rbc-time-view {
    background-color: white;
}

/* Header */
.rbc-button-link {
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
   font-size: 1em;
   font-weight: bold;
}

/* Calendar Content Div */
.toolbar-section + div {
   background: #ffffff;
   border: 1px solid #afafaf;
   box-shadow: 2px 1px 4px rgba(17, 17, 17, 0.2);
   border-radius: 4px;
}

/* Today */
.rbc-today.rbc-day-bg {
   background-color: var(--calendar-today)
}

.rbc-date-cell.rbc-now.rbc-current {
    background-color: var(--dark-grey);
}

/* Week View */
.rbc-time-header {
   height: 100%;
   .rbc-header.rbc-today {
       background-color: var(--dark-grey);
       color: var(--black-white);
   }
}

.rbc-time-view {
    .rbc-label {
        display: none;
    }
}
.rbc-time-content {
    display: none;
}

/* Month View */
.rbc-month-view .rbc-now {
   background-color: var(--zoidberd-black);
   color: white;
}

.rbc-month-header .rbc-header {
    background-color: white;
}

.rbc-month-view .rbc-row-bg {
    background-color: white;
}

.rbc-show-more {
    color: var(--black-white-colormode);
}