@font-face {
    font-family: "Roboto-Black";
    src: local("Roboto"), url("./../src/assets/fonts/Roboto-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Bold";
    src: local("Roboto"), url("./../src/assets/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Light";
    src: local("Roboto"), url("./../src/assets/fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Medium";
    src: local("Roboto"), url("./../src/assets/fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Regular";
    src: local("Roboto"), url("./../src/assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Thin";
    src: local("Roboto"), url("./../src/assets/fonts/Roboto-Thin.ttf") format("truetype");
}

#root {
    height: 100vh;
}

body {
    margin: 0;
    font-family: "Roboto-Regular";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #F5F5F5;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}